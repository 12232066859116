<template>
  <div>
    <div v-if="tableData.description" class="pb-4">
      {{ tableData.description }}
    </div>
    <div class="table-overflow" v-html="$md.render(tableData.body)"></div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="postcss" scoped>
.table-overflow {
  @apply overflow-x-auto w-full;
}
.table-overflow :deep(table[class^='table']) {
  @apply table-auto overflow-scroll w-full;
}

.table-overflow :deep(table[class^='table']) th {
  @apply px-2 py-3 font-bold border-b-2 border-black text-left;
}
.table-overflow :deep(table[class^='table']) td {
  @apply px-2 py-3 border-b border-black;
}

.table-overflow :deep(.table-striped) tr {
  @apply bg-white;
}
.table-overflow :deep(.table-striped) tbody tr:nth-of-type(even) {
  @apply bg-gray-100;
}

.table-overflow :deep(table[class^='table']) a {
  @apply text-web-blue underline;
}
.table-overflow :deep(table[class^='table']) a:hover {
  @apply no-underline;
}
</style>
